@keyframes slide-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slide-up {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.content[data-state='open'] {
  animation: slide-down 200ms ease-out;
  animation-fill-mode: both;
}

.content[data-state='closed'] {
  animation: slide-up 200ms ease-out;
  animation-fill-mode: both;
}
